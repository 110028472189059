import { useRootStore } from './useRootStore'

export function useStores() {
  const { rootStore } = useRootStore()
  if (!rootStore) {
    throw new Error('rootStore is not initialized')
  }

  return {
    navigation: rootStore.navigation,
    page: rootStore.page,
    fieldErrors: rootStore.fieldErrors,
    traversal: rootStore.traversal,
    theme: rootStore.theme,
    history: rootStore.history,
    experimentData: rootStore.experimentData,
    dacChoiceState: rootStore.dacChoiceState
  }
}
