import BugsnagError from './bugsnagError'

export class SuccessfulDacLog extends BugsnagError {
  public traversalId: string | undefined
  public personId: string | undefined
  public transcationId: string | undefined

  constructor(traversalId: string, personId: string, transcationId: string) {
    super('Dac completed successfully')

    this.traversalId = traversalId
    this.personId = personId
    this.transcationId = transcationId
    this.name = 'SuccessfulDacLog'
  }

  getMetadata() {
    return {
      dac: {
        traversalId: this.traversalId,
        personId: this.personId,
        transcationId: this.transcationId
      }
    }
  }
}
