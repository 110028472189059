import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'
import { TrustElements } from 'components/PayoutAccount/TrustElements'
import CheckIcon from '@mui/icons-material/Check'
import DacWidgetSmava from 'components/Smava/Dac/DacWidget'
import { FintecState } from '@finanzcheck/ti-fts-widget'
import { LegalText } from 'components/DigitalAccountCheck/IntroText/LegalText'

interface Props {
  state: FintecState
  setState?: (state: Extract<FintecState, 'agreed' | 'disagreed'>) => void
  widget: JSX.Element | null
  insertDACHandIcon?: boolean
  dacSmavaHandIcon?: boolean
  isSuccessMessageHidden?: boolean
  bankCodeLabel?: string
  bankCodePlaceholder?: string
  initDAC: boolean
}

const StyledEmbededSchnellContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: theme.spacing(1)
}))

const StyledEmbededSchnellStepDetails = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  flexDirection: 'row',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row'
  },
  margin: `${theme.spacing(1)} 0`
}))

const StyledBankPrefferedContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 0),
  backgroundColor: `#E4F6E6`,
  width: 'max-content',
  color: `${theme.palette.success.dark}`,
  padding: `${theme.spacing(1)}`,
  borderRadius: `${theme.spacing(0.5)}`
}))

export const DACEmbeddedStep = ({
  state,
  setState,
  widget,
  insertDACHandIcon,
  dacSmavaHandIcon,
  isSuccessMessageHidden,
  bankCodeLabel,
  bankCodePlaceholder,
  initDAC
}: Props) => {
  return (
    <StyledEmbededSchnellContainer>
      <StyledEmbededSchnellStepDetails>
        <CheckIcon color="primary" />
        <Typography>
          <b>40% höhere Kreditwahrscheinlichkeit</b>
        </Typography>
      </StyledEmbededSchnellStepDetails>

      <StyledEmbededSchnellStepDetails>
        <CheckIcon color="primary" />
        <Typography>
          <b>Sofortauszahlung ab 1h</b> freigeschaltet
        </Typography>
      </StyledEmbededSchnellStepDetails>
      <StyledBankPrefferedContainer>
        <Typography variant="body2">Bevorzugt von Banken</Typography>
      </StyledBankPrefferedContainer>
      {initDAC && (
        <>
          <Typography variant="body2" style={{ margin: '16px 0' }}>
            Loggen Sie sich einmalig mit Ihren gewohnten Zugangsdaten ein.
          </Typography>
          <TrustElements />
          <DacWidgetSmava
            state={state}
            setState={setState}
            widget={widget}
            insertDACHandIcon={insertDACHandIcon}
            dacSmavaHandIcon={dacSmavaHandIcon}
            isSuccessMessageHidden={isSuccessMessageHidden}
            bankCodeLabel={bankCodeLabel}
            bankCodePlaceholder={bankCodePlaceholder}
          />
          <Box px={2} mb={2} mt={1}>
            <LegalText />
          </Box>
        </>
      )}
    </StyledEmbededSchnellContainer>
  )
}
