import { FunctionComponent, memo, useCallback, useEffect } from 'react'
import { observer } from 'mobx-react'
import { FintecRenderProps } from '@finanzcheck/ti-fts-widget'
import {
  broadcastToParent,
  RegisteredEventName
} from '@finanzcheck/catalyst-pollard'
import { Box } from '@mui/material'

import { getOptimizelyTrackingAttribute } from 'utils/tracking'
import { COOKIE_KEY_FCID, getCookie } from 'utils/cookie'
import { useRootStore } from 'stores/utils/hooks/useRootStore'
import { useData } from 'stores/utils/hooks/useData'
import ChoiceComponent from './ChoiceComponent'
import DisagreeInformation from './DisagreeInformation'
import { DACEvent } from '..'

interface OwnProps {
  isBannerIconOutline?: boolean
}

type Props = Omit<FintecRenderProps, 'widget'> & OwnProps

const DacChoice: FunctionComponent<Props> = observer(
  ({ state, setState, isBannerIconOutline }) => {
    const {
      rootStore: { dacChoiceState, setDacChoiceState }
    } = useRootStore()

    const { data } = useData()

    useEffect(() => {
      if (state !== dacChoiceState) {
        setDacChoiceState(state)
      }
    }, [state, setDacChoiceState, dacChoiceState])

    const handleChoice = useCallback(
      (isAgreed: boolean) => {
        broadcastToParent({
          eventName: RegisteredEventName.tracking,
          data: {
            event: isAgreed
              ? DACEvent.STATE_CHANGE_AGREED
              : DACEvent.STATE_CHANGE_DISAGREED,
            context: {
              Optimizely: { userId: getCookie(COOKIE_KEY_FCID) },
              ...getOptimizelyTrackingAttribute(data)
            }
          }
        })

        setState(isAgreed ? 'agreed' : 'disagreed')

        if (!isAgreed) {
          document.getElementById('next-submit')?.click()
        }
      },
      [setState, data]
    )

    const shouldShowChoice = ['initial', 'disagreed'].includes(state)

    if (state === 'success') {
      return null
    }

    return (
      <Box px={2} pt={2} mb={2} data-testid="dac-choice">
        {shouldShowChoice && (
          <>
            <ChoiceComponent onChange={handleChoice} />
            <DisagreeInformation isBannerIconOutline={isBannerIconOutline} />
          </>
        )}
      </Box>
    )
  }
)

export default memo(DacChoice)
