import {
  CONFIG_DDF_MARKETING,
  CONFIG_DDF_SMAVA_SEPARATED_DAC,
  ConfigName,
  isConfigWithoutInactivityRedirection
} from 'config/utils/config'
import { MIDDLE_AGE, MIN_AGE, YOUNG_AGE } from 'constants/components'

const SHORT_TIMEOUT = 120000 // 1 minute
const MEDIUM_TIMEOUT = 180000 // 3 minutes
export const LONG_TIMEOUT = 300000 // 5 minutes

/**
 * Determines the timeout.
 *
 * @param {boolean} isSmava
 * @param {number} debtorsAge
 * @param {ConfigName} [configName]
 * @returns {number | null} The timeout duration in milliseconds or null if no timeout is applicable.
 */
export const determineTimeout = (
  isSmava: boolean,
  debtorsAge: number,
  configName?: ConfigName
): number | null => {
  if (isConfigWithoutInactivityRedirection(configName)) {
    return null
  }

  if (configName === CONFIG_DDF_SMAVA_SEPARATED_DAC) {
    if (debtorsAge >= MIN_AGE && debtorsAge < YOUNG_AGE) {
      return MEDIUM_TIMEOUT
    }

    if (debtorsAge >= YOUNG_AGE && debtorsAge < MIDDLE_AGE) {
      return SHORT_TIMEOUT
    }

    if (debtorsAge >= MIDDLE_AGE) {
      return null
    }
  }

  if (
    (!isSmava && debtorsAge < YOUNG_AGE) ||
    configName === CONFIG_DDF_MARKETING ||
    (isSmava && debtorsAge >= MIN_AGE && debtorsAge < YOUNG_AGE)
  ) {
    return MEDIUM_TIMEOUT
  }

  return SHORT_TIMEOUT
}
